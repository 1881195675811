.wrapper {
  width: 200px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 17px;
    margin-bottom: 10px;
    cursor: pointer;

    .icon-close {
      display: flex;

      > svg {
        transition: all 0.5s ease;
        rotate: 180deg;
      }
    }

    .icon-open {
      display: flex;

      > svg {
        transition: all 0.5s ease;
        rotate: 0deg;
      }
    }
  }

  .content-open {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .content-close {
    display: none;
  }

  hr {
    margin: 20px 0;
    border-color: #e6e6e6;
    opacity: 1;
  }
}
