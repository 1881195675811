.container {
  .inputs {
    display: flex;
    gap: 10px;
    align-items: center;

    *::placeholder {
      font-size: 13px;
    }

    .inputs-divider {
      width: 14px;
      height: 2px;
      background-color: var(--grays-gray-400);
    }
  }

  .error {
    margin-block: 10px;
    p {
      margin: 0;
    }
  }

  .price-items {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .price-item {
      display: flex;
      align-items: center;
      gap: 8px;

      * {
        cursor: pointer;
      }
    }
  }

  .price-button {
    width: 107px;
    height: 33px;
    padding: 0;
  }
}
