.modal_dialog {
  max-width: 660px !important;
}

.modal {
  z-index: 10000 !important;
}

.modal_backdrop {
  z-index: 10000 !important;
}

.container {
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    .close_icon {
      cursor: pointer;
    }
  }

  .hr {
    margin: 10px 0;
    background: #e2e2e2;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 10px 0;
  }

  .save_button {
    margin-top: 30px;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 15px;
    .header {
      padding: 5px 0;
    }

    .content {
      padding: 5px 0;
      gap: 5px;
      flex-wrap: nowrap;
    }

    .save_button {
      margin-top: 10px;
    }
  }
  .mobile_user_setting_modal_content {
    border-radius: 0 !important;
    border: none;
    transform: translateY(100%);
    animation: slide-up 0.3s ease-out forwards;
  }

  .modal_dialog {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: fixed !important;
    bottom: 0 !important;
    left: 0;
    width: 100% !important;
    margin: 0 !important;
  }

  .slide-down {
    animation: slide-down 0.4s ease-out forwards;
  }
}

@keyframes slide-up {
  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  to {
    transform: translateY(100%);
  }
}
