$fontFamily: 'Rubik';
$borderRadius: 4px;

@mixin prefix($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
  #{$property}: $value;
}

.button {
  width: 100%;
  color: var(--default-white);
  font-family: $fontFamily;
  font-size: 16px;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 8px;
  padding-inline: 12px;
  outline: none;
  border: none;
  @include prefix('border-radius', $borderRadius);

  .children {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}

.spinner {
  svg {
    animation: rotate 1s linear infinite;
  }
}

.primary,
.outline-primary {
  border: 1px solid var(--primary-primary-dark);

  &:hover {
    border: 1px solid var(--primary-primary-dark);
  }
}

.primary {
  * {
    color: #fff !important;
  }
  background: var(--primary-primary);
  &:hover {
    background: var(--primary-primary-dark);
  }
}

.success {
  border: 1px solid var(--greens-success-green-500);
  background: var(--greens-success-green-500);
  &:hover {
    border: 1px solid var(--greens-success-green-600);
    background: var(--greens-success-green-600);
  }
  * {
    color: #fff !important;
  }
}

.outline-primary {
  background: var(--default-white);

  &:hover {
    background: var(--primary-primary-light);
  }
  * {
    color: var(--primary-primary) !important;
  }
}

.ghost {
  background: var(--default-white);
  * {
    color: var(--secondary-secondary);
  }
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
  opacity: 0.5;
  color: var(--default-white);
  border: none !important;
}

.gradient {
  background: linear-gradient(90deg, #feae00 0%, #ff1607 100%);
  span {
    color: #fff;
  }
}

@keyframes rotate {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}
