.product-attributes {
  margin-top: 16px;
  padding: 32px 10px 0;
  border-top: 1px solid #e2e2e2;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    padding-bottom: 10px;
  }

  &__list {
    display: grid;
    justify-content: space-between;
    gap: 16px;
    grid-template-columns: 50% 50%;
    padding-bottom: 10px;
    &__item {
      background-color: var(--grays-gray-100);
      border-radius: 6px;
      grid-template-columns: 1fr 1fr;
      display: grid;
      gap: 10px;
      padding: 16px;
      overflow: hidden;
      > span {
        color: var(--secondary-secondary-dark);
        white-space: nowrap; /* Prevents text from wrapping to a new line */
        overflow: hidden; /* Hides the overflowed text */
        text-overflow: ellipsis;
        &:nth-child(2) {
          text-align: right;
        }
      }
    }
  }

  &.product-attributes--mobile {
    border-top: none;
    margin-top: 0;
    padding: 16px 15px 0;

    .product-attributes__title {
      padding-bottom: 5px;
    }
    .product-attributes__list {
      grid-template-columns: 100%;
      gap: 0;
      padding-bottom: 0;

      .product-attributes__list__item {
        background-color: transparent;
        border-radius: 0;
        border-bottom: 1px solid var(--secondary-border-1);
        gap: 30px;
        padding: 16px 0;

        > span {
          &:nth-child(2) {
            text-align: left;
          }
        }
      }
    }
  }
}
