.checkbox {
  border-color: rgba(102, 102, 102, 0.5) !important;
  margin: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.checkbox:hover {
  border-color: var(--primary-primary) !important;
}

.checkbox:active {
  background-color: #fff !important;
}

.checkbox:focus {
  border-color: var(--primary-primary) !important;
}
.checkbox:checked {
  background-color: var(--primary-primary) !important;
  border-color: var(--primary-primary) !important;
}

.checkbox:focus {
  box-shadow: none !important;
}

.checkbox:disabled {
  background-color: #66666680 !important;
  border-color: #66666680 !important;
}
