.container {
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid var(--secondary-border-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 199px;
  height: 75px;
  gap: 10px;

  .country {
    align-items: center;
    display: flex;
    gap: 5px;
  }

  .languages {
    display: flex;
    gap: 10px;
    align-items: center;
    .language {
      display: flex;
      gap: 5px;
      > label,
      input {
        cursor: pointer;
      }
    }
  }
}
.selected_container {
  border: 1px solid var(--primary-primary);
}

@media (max-width: 576px) {
  .container {
    height: 75px;
  }
}
