.multiple-items-wrapper {
  .items {
    overflow: auto;
    overflow-x: hidden;
    max-height: 230px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .item {
      height: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      .input {
        background-color: var(--grays-gray-100);
        cursor: pointer;
      }
    }
  }
}
