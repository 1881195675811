.product-information {
  padding-bottom: 64px;

  .box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 30px;
    gap: 16px;
    margin-top: 16px;
    border-radius: 6px;
    border: 1px solid var(--secondary-border-1);

    .information-seciton {
      display: flex;

      .image {
        border-radius: 8px;
        flex-shrink: 0;
      }

      .content {
        flex: 1;

        .title {
          padding: 0 10px;
        }

        .body {
          padding: 10px;
          color: var(--secondary-secondary-dark);
          font-family: Rubik;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;

          ul {
            margin: 0;
            padding-left: 24px;
          }
        }
      }
    }
  }
}
