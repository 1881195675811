$borderRadius: 4px;

@mixin prefix($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
  #{$property}: $value;
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  width: 100%;
  border: 1px solid var(--grays-gray-400);
  background-color: var(--grays-gray-100);
  padding-inline: 10px;
  padding-block: 15px;
  @include prefix('border-radius', $borderRadius);
}

.input::placeholder {
  color: var(--secondary-secondary-light, #abb5be);
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-small {
  padding-block: 6.5px;
  font-size: 14px;
}

.input-medium {
  padding-block: 16px;
}

.password {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.has-error {
  border: 1px solid var(--reds-errors-red-500) !important;
  background-color: #fff1f1 !important;
}

@media (max-width: 500px) {
  .input::placeholder {
    font-size: 13px;
  }
  .input-medium {
    padding-block: 6.5px !important;
    font-size: 14px !important;
  }
}
