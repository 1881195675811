.tree-node {
  display: flex;
  align-items: center;
  height: 15px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  column-gap: 5px;

  &_name {
    cursor: pointer;

    &:hover {
      color: var(--primary-primary) !important;
    }
  }

  &_icon {
    cursor: pointer;
    line-height: 0;
  }

  &_placeholder {
    height: 10px;
    width: 11px;
  }

  &_list {
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0;
  }
}
