.product-information {
  padding-bottom: 56px;
}

.breadcrumb {
  margin: 20px 0;
}

.banners-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.wholesale {
  margin-top: 30px;
  border-bottom: 1px solid var(--secondary-border-2);
}
