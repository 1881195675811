.multi-select-wrapper {
  .input-container {
    margin-bottom: 10px;
  }

  .item-single {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .items {
    overflow: auto;
    overflow-x: hidden;
    max-height: 230px;
    display: flex;
    flex-direction: column;

    gap: 10px;
    .item {
      height: 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      .input {
        background-color: var(--grays-gray-100);
        cursor: pointer;
      }
    }
  }

  .color-items {
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-items: center;
    padding: 5px;
    .color-item {
      position: relative;
      height: fit-content;
      label {
        word-wrap: break-word;
        max-width: 48.25px;
      }
      &:hover input[type='checkbox'] {
        box-shadow: 0 0 0 2px var(--secondary-border-1);
        border: 1px solid white;
      }
      input[type='checkbox'] {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: var(--circle-bgColor);
        border: 1px solid var(--secondary-border-1);

        cursor: pointer;
        &:checked {
          box-shadow: 0 0 0 2px var(--circle-bgColor);
          background-color: var(--circle-bgColor);
          --bs-form-check-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='5' width='5' viewBox='0 0 448 512'%3E%3Cpath fill='%23ffffff' d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/%3E%3C/svg%3E");
          border: 2px solid white;
          background-size: 11px;
          background-position: center;
        }
      }
      .input {
        text-overflow: initial;
        white-space: initial;
        background-color: var(--grays-gray-100);
        cursor: pointer;
      }
    }
  }
}
