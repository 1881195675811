.banner {
  width: 100%;
  border-radius: 5px;
  background-color: #e9ecef;
  padding: 0 10px;
  display: grid;
  grid-template-columns: 1fr 80px;
  gap: 5px;
  .banner__content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 19px 0px 10px 10px;
    .banner__content__title {
      color: #333333;
      margin-bottom: 0;
    }
    .banner__content__badges {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 5px;
      gap: 5px;
    }
  }
  .banner__image {
    display: block;
    width: 80px;
    height: 80px;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
