.favorite-button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 9999px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.favorite-button__right-67 {
  right: 67px;
}

.favorite-button__active {
  border: 1px solid #f26b26;
}
