.header_title_h1 {
  text-transform: capitalize;
}

@media (min-width: 768px) {
  .header_title_h1 {
    font-size: 24px;
    /* Adjust font size for larger screens */
  }
}
