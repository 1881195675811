$font-family: 'Rubik';

.RED {
  border: 1px solid #ff430b !important;

  .content {
    border: none !important;
  }
}

.PURPLE {
  border: 1px solid #8f07b6 !important;

  .content {
    border: none !important;
  }
}

.container {
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  border: 1px solid transparent;
  overflow: hidden;

  .static {
    background: linear-gradient(90deg, #feae00 0%, #ff1607 100%);
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    padding: 2px;
    span {
      color: #fff;
    }
  }

  .image {
    width: 100%;
    position: relative;

    .image_wrapper {
      position: relative;
      width: 100%;

      img {
        position: relative !important;
        width: 100%;
        height: auto !important;
        max-height: 100%;
        aspect-ratio: 2/3;
      }
    }

    .action {
      .badges {
        width: 42px;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 10px;
        left: 10px;

        .badge {
          width: 100%;
          height: 39px;
          position: relative;
        }
      }

      .edit {
        position: absolute;
        bottom: 50%;
        right: 10px;
        opacity: 0;
        z-index: 2;
        transition: opacity 0.2s ease-in-out;
      }

      .mobile_edit {
        opacity: 1;
      }
    }

    .count-down {
      position: absolute;
      left: 9px;
      bottom: 9px;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
    }
  }

  .content {
    padding: 5px;
    position: relative;
    border: 1px solid var(--secondary-border-2);

    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .price_no_badge {
      padding: 0 4px;
      text-align: center;
      height: 31px;
      margin-top: 5px;
    }

    .price {
      margin-top: 5px;
      // this rule remove border right form badge component because it collapsed with price border
      > div > :first-child {
        border-right: unset !important ;
      }
    }

    .name-reviews {
      margin-top: 5px;
      column-gap: 5px;

      .name {
        text-align: left;
        padding: 0 4px;

        > span {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          white-space: normal;
          text-overflow: ellipsis;
          height: 34px;
          width: 100%;
          margin-bottom: 5px;
        }
      }
    }

    .promotion {
      margin: 5px 0;
      display: flex;
      align-items: center;
      padding: 0 4px;

      gap: 2px;
    }

    .category {
      height: fit-content;
      margin-top: 5px;
      padding: 0 4px;
      height: 12px;
      display: flex;
      text-align: left;
      align-items: center;
    }

    .sizes {
      display: flex;
      gap: 5px;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 4px;
      margin-top: 5px;
      height: 30px;
      overflow: hidden;

      .more_sizes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2px;
        background-color: rgba(242, 107, 38, 0.15);
        border: 1px solid rgba(226, 108, 45, 0.15);
        border-radius: 4px;
        width: 25px;
        height: 100%;
      }
    }
  }
}

@media (max-width: 567px) {
  .container {
    .content {
      .name-reviews {
        height: 24px;
        margin-top: 5px;

        .name {
          height: 24px;

          > span {
            text-align: left;
            height: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }
}

// Promoted product styles
.productItem__action-buttons {
  margin-top: 5px;
  display: flex;
  gap: 8px;

  &__add-to-basket {
    padding: 8px 15px;
    border-radius: 6px;
    border: 1px solid #f26b26;
    background: transparent;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    span {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      color: #f26b26;
    }

    &:hover {
      background: #f26b26;

      .selectButton__button-progress__container {
        > div > * {
          border-color: #fff transparent transparent transparent !important;
        }
      }

      span {
        color: #fff;
      }
    }
  }

  &__variations-button-error {
    border: 1px solid #f00;

    span {
      color: #f00;
    }

    .productItem__action-buttons__variations-button__icon {
      svg {
        path {
          fill: #f00;
        }
      }
    }
  }
}

.selectButton__button-progress__container {
  height: 17px;
  display: block;
}

// badge color classes on static area
.light_blue {
  background: #d2f3fb !important;
  span {
    color: #006dc9 !important;
  }
}

.red {
  background: #ff3600 !important;
  span {
    color: #ffffff !important;
  }
}

.blue {
  background: #084298 !important;
  span {
    color: #ffffff !important;
  }
}
.purple {
  background: #f7ddff !important;
  span {
    color: #8800b1 !important;
  }
}
.green {
  background: #ceffca !important;
  span {
    color: #09b501 !important;
  }
}

.orange {
  background: #ffedd2 !important;
  span {
    color: #ff5800 !important;
  }
}

.yellow {
  background: #faf2a9 !important;
  span {
    color: #ff5800 !important;
  }
}

@media (max-width: 406px) {
  .container {
    max-width: 230px;

    .content {
      .sizes {
        gap: 3px;
      }
    }
  }
}

@media (max-width: 431px) {
  .lowest-price-tr {
    min-height: 30px !important;
  }
}

@media (max-width: 458px) {
  .lowest-price-en {
    min-height: 30px !important;
  }
}

@media (min-width: 458px) {
  .container {
    .content {
      .sizes {
        margin-top: 10px;
        height: fit-content;

        .more_sizes {
          height: 40px;
          min-width: 38px;
        }
      }
    }
  }
}
