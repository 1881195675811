.overlay {
  min-height: 100dvh;
  max-height: 100dvh;
  background: #000;
  opacity: 0;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 3000;
  visibility: hidden;
  transition: opacity 0.4s ease, visibility 0.4s ease;
}
.overlay_open {
  opacity: 0.3;
  visibility: visible;
}

.wrapper {
  width: 80%;
  background: #ffffff;
  position: fixed;
  top: 0;
  min-height: 100dvh;
  max-height: 100dvh;
  overflow-y: auto;
  z-index: 3001;
  left: -100%;
  transition: left 0.4s ease;

  &--open {
    left: 0;
  }

  &__header {
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    margin: 0 auto;
    width: 100%;
    background: #ffffff;
    height: 54px;
    padding: 0 10px;

    button {
      border: none;
      outline: none;
      background: none;
      display: block;
    }

    .wrapper__header__search {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }

  /**
   * Styles for the mobile sidebar component in the header of the main layout.
   * This includes styles for the search functionality, tabs, authentication section, and language section.
   */
  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0 0 19px;
    height: 100%;
    min-height: calc(100dvh - 54px);

    &__search-input {
      width: 100%;
      height: 50px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        font-size: 12px;
        font-weight: 400;
        border: none;
        text-align: left;
        padding-left: 4px;
        width: 140px;
        max-width: 80%;
        color: #333333;
        transition: width ease 0.1s;
      }
    }

    // tabs buttons
    .wrapper__body__tabs-buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      padding: 10px 15px;

      button {
        line-height: 45px;
        background: none;
        outline: none;
        border: none;
        border-bottom: 3px solid #e2e2e2;
        width: 100%;
        span {
          color: #666666;
        }
        &.active {
          border-bottom-color: #f26b26;
          span {
            color: #f26b26;
          }
        }
      }
    }

    // tabs body
    .wrapper__body__tabs-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 5px 15px 0;
      gap: 10px;
      width: 100%;
    }

    // auth section
    .wrapper__body__auth-section {
      margin-top: auto;
      margin-bottom: 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding: 15px 15px 0;
      width: 100%;
      .wrapper__body__auth-section__title {
        color: #f26b26;
        margin-bottom: 15px;
      }
      .wrapper__body__auth-section__welcome {
        display: flex;
        gap: 4px;
        color: #666666;
      }

      &.wrapper__body__auth-section--logged-in {
        .wrapper__body__auth-section__title {
          margin-bottom: 0;
        }
        .wrapper__body__auth-section__welcome {
          margin-bottom: 10px;
        }
      }

      // auth actions
      .wrapper__body__auth-section__auth-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .wrapper__body__auth-section__auth-actions__button {
          border: 1px solid #e2e2e2;
          padding: 10px 20px;
          width: 50%;
          text-align: center;
          display: block;
          span {
            color: #666666;
          }
          &:nth-child(1) {
            border-radius: 6px 0px 0px 6px;
          }
          &:nth-child(2) {
            border-radius: 0px 6px 6px 0px;
          }
          &.wrapper__body__auth-section__auth-actions__button--active {
            border-color: #f26b26;
            background: #f8f2ef;
            span {
              color: #f26b26;
            }
          }
        }
      }

      // profile actions
      .wrapper__body__auth-section__profile-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 10px;
        .wrapper__body__auth-section__profile-actions__button {
          border: 1px solid #e2e2e2;
          padding: 10px 20px;
          width: 100%;
          text-align: center;
          display: block;
          border-radius: 6px;
          span {
            color: #666666;
          }
          &:nth-child(1) {
            width: 100%;
          }
          &:nth-child(2),
          &:nth-child(3) {
            width: calc((100% - 10px) / 2);
          }
          &.wrapper__body__auth-section__profile-actions__button--active {
            border-color: #f26b26;
            background: #f8f2ef;
            span {
              color: #f26b26;
            }
          }
        }
      }

      .wrapper__body__auth-section__message {
        margin-top: 10px;
        margin-bottom: 0;
        width: 100%;
        text-align: center;
      }
    }

    // language
    .wrapper__body__language-section {
      margin-bottom: 0;
      padding: 25px 15px 0;

      .wrapper__body__language-section__flag {
        display: block;
        border-radius: 20px;
        overflow: hidden;
        object-fit: cover;
      }

      .wrapper__body__language-section__title {
        color: #f26b26;
        margin-bottom: 15px;
        display: block;
      }
      .wrapper__body__language-section__button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        background: none;
        div {
          border: 2px solid #f26b26;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          padding: 10px 20px;
          background: #ffffff;
          margin-bottom: 0;
          span {
            color: #f26b26;
          }
        }
        .wrapper__body__language-section__message {
          margin-left: 10px;
          color: #0d6efd;
        }
      }
    }

    .wrapper__body__signout {
      margin-top: 15px;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      padding: 10px 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      outline: none;
      display: flex;
      gap: 5px;
      margin-left: 15px;
      span {
        color: #666666;
      }
    }
  }
}

.search_mode {
  width: 100% !important;
}
