$font-family: 'Rubik';

.productItem__action-buttons__variations {
  position: relative;
  display: flex;
}

.productItem__action-buttons__variations-button {
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  max-width: 64px;
  height: 100%;

  span {
    color: #666;
    font-family: $font-family;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  svg {
    margin-top: 1px;
  }

  .productItem__action-buttons__variations-button__icon {
    height: 100%;
    width: 13px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      path {
        fill: #666;
      }
    }
  }
}

.productItem__action-buttons__variations-button-open {
  border: 1px solid #f26b26;
  color: #f26b26;

  span {
    color: #f26b26;
  }

  .productItem__action-buttons__variations-button__icon {
    svg {
      path {
        fill: #f26b26;
      }
    }
  }
}

.productItem__action-buttons__variations-button-selected {
  border: 1px solid #f26b26;
  color: #f26b26;

  span {
    color: #f26b26;
  }

  .productItem__action-buttons__variations-button__icon {
    svg {
      path {
        fill: #f26b26;
      }
    }
  }
}

.productItem__variations__menu_wrapper {
  width: 220px;
  padding: 20px 0;

  border-radius: 6px;
  border: 0.2px solid rgba(51, 51, 51, 0.1);
  background: var(--White, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.productItem__variations__menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.productItem__variations__menu-item {
  padding: 0 20px;
  height: 26px;
  display: flex;
  align-items: center;

  &:hover {
    background: rgba(217, 217, 217, 0.5);
  }

  span {
    color: #333;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}
